var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    {
      staticStyle: { cursor: "pointer" },
      on: {
        click: function ($event) {
          return _vm.openSupplierIntegration(_vm.supplierIntegration.id)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "flex flex-wrap items-center" },
        [
          _c("vs-avatar", {
            staticClass: "supplier-logo-avatar mr-2",
            attrs: {
              src: _vm.supplierIntegration.icon,
              text: _vm.supplierIntegration.name,
              size: "50px",
            },
          }),
          _c("div", {}, [
            _c(
              "div",
              { staticClass: "flex items-center justify-start" },
              [
                _c("h5", { staticClass: "ml-2 mr-2" }, [
                  _vm._v(_vm._s(_vm.supplierIntegration.name)),
                ]),
                _vm.supplierIntegration.isPremium
                  ? _c("vs-chip", { attrs: { color: "warning" } }, [
                      _c("span", { staticClass: "text-xs" }, [_vm._v("P")]),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _c("p", { staticClass: "ml-2 mt-1 text-sm" }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.supplierIntegration.productType) +
                    " • " +
                    _vm._s(_vm.supplierIntegration.apiType)
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c("p", { staticClass: "mt-2 text-sm supplier-pricing" }, [
        _c("span", [_vm._v(" Setup Price: ")]),
        _c("span", {}, [
          _vm._v("£ " + _vm._s(_vm.supplierIntegration.setupPrice.toFixed(2))),
        ]),
        _vm._v("\n     | \n    "),
        _c("span", [_vm._v(" Monthly: ")]),
        _c("span", { staticClass: "text-sm" }, [
          _vm._v(
            "£ " + _vm._s(_vm.supplierIntegration.monthlyPrice.toFixed(2))
          ),
        ]),
      ]),
      _c("p", { staticClass: "mt-2" }, [
        _vm._v(_vm._s(_vm.strippedDescription)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }