var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "vx-row" }, [
      _c("div", { staticClass: "vx-col w-full md:w-1/2 lg:w-3/4" }, [
        _c(
          "div",
          { staticClass: "flex flex-wrap justify-start" },
          _vm._l(_vm.allProductTypes, function (productType, index) {
            return _c(
              "button",
              {
                key: index,
                staticClass:
                  "mr-2 mt-2 vs-component vs-button vs-button-primary",
                class: {
                  "vs-button-filled":
                    _vm.activeProductTypeId === productType.id,
                  "vs-button-border":
                    _vm.activeProductTypeId !== productType.id,
                },
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.filterProductType(productType.id)
                  },
                },
              },
              [
                _c("span", {
                  staticClass: "vs-button-backgroundx vs-button--background",
                  staticStyle: {
                    opacity: "1",
                    left: "20px",
                    top: "20px",
                    width: "0px",
                    height: "0px",
                    transition:
                      "width 0.3s ease 0s, height 0.3s ease 0s, opacity 0.3s ease 0s",
                  },
                }),
                _c("span", { staticClass: "vs-button-text vs-button--text" }, [
                  _vm._v(_vm._s(productType.name)),
                ]),
                _c("span", {
                  staticClass: "vs-button-linex",
                  staticStyle: {
                    top: "auto",
                    bottom: "-2px",
                    left: "50%",
                    transform: "translate(-50%)",
                  },
                }),
              ]
            )
          }),
          0
        ),
      ]),
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-1/2 lg:w-1/4 lg:pl-0 mb-4" },
        [
          _c("vs-input", {
            staticClass: "w-full mt-2",
            attrs: {
              "icon-pack": "feather",
              icon: "icon-search",
              placeholder: "Search",
            },
            on: {
              input: function ($event) {
                _vm.activeProductTypeId = 0
              },
            },
            model: {
              value: _vm.searchText,
              callback: function ($$v) {
                _vm.searchText = $$v
              },
              expression: "searchText",
            },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "vx-row mt-4 match-height" },
      _vm._l(
        _vm.filteredSupplierIntegrations,
        function (supplierIntegration, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "vx-col w-full md:w-1/2 lg:w-1/3 xl:w-1/4 mt-4",
            },
            [
              _c("supplier-integrations-card", {
                attrs: {
                  supplierIntegration: supplierIntegration,
                  applicationId: _vm.applicationId,
                },
              }),
            ],
            1
          )
        }
      ),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h2", { staticClass: "app-heading mb-4" }, [
        _vm._v("Suppliers Directory"),
      ]),
      _c("p", { staticClass: "text-muted mb-2" }, [
        _vm._v(
          "Your Travelify application can connect to a variety of third party suppliers and integrations to extend the range of products and functionality. You will need to have an account set up with each supplier/integration before you connect them"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }